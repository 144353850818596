export async function callMethod(method: string, args: any = {}) {
  // const url = 'http://localhost:3000/call';
  const url = 'https://pyk8rixa3j.execute-api.us-east-1.amazonaws.com/prod/call';
  const r = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ method, args,
      
      authToken: localStorage.getItem('authToken')
    
    })
  });

  return await r.json();
}
