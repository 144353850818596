import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClientProvider } from 'react-query';
import { queryClient } from './queryClient';

function Comp(props: any) {
  const [isOpen, setIsOpen] = useState(true);
  return props.func({
    close: () => {
      setIsOpen(false);
    },
    onClosed: props.onClosed,
    isOpen,
  });
}
export function presentDialog(func: (props: { isOpen: boolean; close: any; onClosed: any; }) => any) {
  const cont = document.createElement('div');
  document.body.appendChild(cont);
  const root = ReactDOM.createRoot(cont);
  root.render(
    <QueryClientProvider client={queryClient}>
      <Comp
        func={func}
        onClosed={() => {
          root.unmount();
          cont.parentNode?.removeChild(cont);
        }} />
    </QueryClientProvider>
  );
}
