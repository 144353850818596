import { useEffect, useRef, useState } from 'react';
import './App.scss';
import '@blueprintjs/core/lib/css/blueprint.css';
import "@blueprintjs/table/lib/css/table.css";
import { CardsPage } from './pages/CardsPage';
import { UsersPage } from './pages/UsersPage';
import { Button, Dialog, DialogBody, DialogFooter, FormGroup, InputGroup } from '@blueprintjs/core';
import { callMethod } from './callMethod';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Sugar from 'sugar';
import { UserContext } from './UserContext';
Sugar.extend();

function SignIn() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const submit = async () => {
    setLoading(true);
    const r = await callMethod('signIn', {
      username,
      password,
    });

    if (r.authToken) {
      localStorage.setItem('authToken', r.authToken);
      window.location.reload();
    }
    else {
      alert('Invalid');
      setLoading(false);
    }
  }
  return (
    <>

    <Dialog
      isOpen
      title="Sign In"
      isCloseButtonShown={false}
    >

      <DialogBody>
        <FormGroup
          label="Username"
          labelFor="number-input"
        >
          <InputGroup value={username} onChange={e => setUsername(e.target.value)} id="number-input" placeholder="" />
        </FormGroup>

        <FormGroup
          label="Password"
          labelFor="password-input"
        >
          <InputGroup value={password} onChange={e => setPassword(e.target.value)} id="password-input" placeholder="" type="password"
            onKeyDown={e => {
              if (e.key === 'Enter') {
                submit();
              }
            }}
          />
        </FormGroup>
      </DialogBody>
      <DialogFooter actions={<>
      <Button disabled={loading} className="save" intent="primary" text={loading ? 'Submitting...' : 'Submit'}
        onClick={submit} />
    </>} />
    </Dialog>
    </>
  )
}

const router = createBrowserRouter([
  {
    path: '/',
    element: <CardsPage onNav={() => {}} />,
  },
  {
    path: '/users',
    element: <UsersPage onNav={() => {}} />,
  },
]);


const router2 = createBrowserRouter([
  {
    path: '/',
    element: <SignIn />,
  },
  {
    path: '/users',
    element: <SignIn />
  },
]);





function App() {
  const authToken = localStorage.getItem('authToken');
  const [userState, setUserState] = useState(null);
  useEffect(() => {
    if (authToken) {
      (async () => {
        const r = await callMethod('user', { authToken });
        setUserState(r.user);
      })();  
    }
  }, []);

  if (authToken) {
    return (
      userState && <UserContext.Provider value={{ user: userState }}>
        <RouterProvider router={router} />
      </UserContext.Provider>
    );
  }
  else {
    return <RouterProvider router={router2} />
  }
}

export default App;
