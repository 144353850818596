import React from 'react';
import ReactLoading from 'react-loading';

export function Loading() {
  return (
    <div className="loading">
      <ReactLoading
        width={50}
        height={50}
        type="spokes"
        color="gray" />
    </div>
  );
}
