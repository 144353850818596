import React, { useContext, useState } from 'react';
import { Header } from '../Header';
import { Button, Dialog, DialogBody, DialogFooter, FormGroup, HTMLSelect, HTMLTable, InputGroup } from '@blueprintjs/core';
import { callMethod } from '../callMethod';
import { useQuery } from 'react-query';
import { presentDialog } from '../presentDialog';
import { queryClient } from '../queryClient';
import { Loading } from '../Loading';
import _ from 'lodash';
import { UserContext } from '../UserContext';

async function getUsers(): Promise<any[]> {
  return await callMethod('users.query');
}

interface CreateUserDialogProps {
  onClose: any
  onSubmit: any
  onClosed?: any
  isOpen: boolean
}
function CreateUserDialog(props: CreateUserDialogProps) {
  const [username, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [role, setRole] = useState('user');
  const [saving, setSaving] = useState(false);
  return (
    <Dialog
      title="Create User"
      isOpen={props.isOpen}
      onClose={props.onClose}
      onClosed={props.onClosed}
    >
      <DialogBody>
        <FormGroup label="Username">
          <InputGroup value={username} onChange={e => setUserName(e.target.value)} />
        </FormGroup>
        <FormGroup label="Name">
          <InputGroup value={name} onChange={e => setName(e.target.value)} />
        </FormGroup>
        <FormGroup label="Role">
          <HTMLSelect value={role} onChange={e => setRole(e.target.value)}>
            <option value="user">User</option>
            <option value="admin">Admin</option>
          </HTMLSelect>
        </FormGroup>
        <FormGroup label="Password">
          <InputGroup value={password} onChange={e => setPassword(e.target.value)} />
        </FormGroup>
      </DialogBody>
      <DialogFooter
        actions={(
          <>
            <Button
              disabled={saving}
              className="save"
              intent="primary"
              text={saving ? 'Submitting...' : 'Submit'}
              onClick={() => {
                props.onSubmit({ username, name, role, password });
                setSaving(true);
              }}
            />
          </>
        )}
      />
    </Dialog>
  )
}

interface EditUserDialogProps {
  onClose: any
  onSubmit: any
  onClosed?: any
  isOpen: boolean

  user: any

  onClickChangePassword: (confirmed: () => void, done: () => void) => void;
  onClickDelete: (confirmed: any) => void;
}
function EditUserDialog(props: EditUserDialogProps) {
  const [username, setUserName] = useState(props.user.username);
  const [name, setName] = useState(props.user.name);
  const [role, setRole] = useState(props.user.role);

  const [saving, setSaving ] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [changingPassword, setChangingPassword] = useState(false);
  return (
    <Dialog
      title="Edit User"
      isOpen={props.isOpen}
      onClose={props.onClose}
      onClosed={props.onClosed}
    >
      <DialogBody>
        <FormGroup label="Username">
          <InputGroup value={username} onChange={e => setUserName(e.target.value)} />
        </FormGroup>
        <FormGroup label="Name">
          <InputGroup value={name} onChange={e => setName(e.target.value)} />
        </FormGroup>

        <FormGroup label="Role">
          <HTMLSelect value={role} onChange={e => setRole(e.target.value)}>
            <option value="user">User</option>
            <option value="admin">Admin</option>
          </HTMLSelect>
        </FormGroup>

        <Button
          disabled={changingPassword}
          text={changingPassword ? 'Changing Password...' : 'Change Password'}
          onClick={async () => {
            props.onClickChangePassword(() => {
              setChangingPassword(true);
            }, () => {
              setChangingPassword(false);
            });
          }}
        />
      </DialogBody>
      <DialogFooter
        actions={(
          <>
            <Button
              disabled={deleting} 
              text={deleting ? 'Deleting...' : 'Delete'}
              intent="danger"
              onClick={async () => {
                props.onClickDelete(() => {
                  setDeleting(true);
                });
              }}
            />
            <Button
              disabled={saving}
              className="save"
              intent="primary"
              text={saving ? 'Saving...' : 'Save'}
              onClick={() => {
                props.onSubmit({ username, name, role });
                setSaving(true);
              }}
            />
          </>
        )}
      />
    </Dialog>
  )
}

interface UsersPageProps {
  onNav?: any;
}
export function UsersPage(props: UsersPageProps) {
  const { data, isLoading } = useQuery(['users'], () => getUsers());
  const {user:userr} = useContext(UserContext);

  return (
    <div className="users-page">
      <Header
        active="users"
        onNav={props.onNav}
      />

      {isLoading && <Loading />}
      {!isLoading && <HTMLTable className="users-table" bordered interactive={userr.role === 'admin'} striped>
        <thead>
          <tr>
            <th className="username">Username</th>
            <th className="name">Name</th>
            <th className="role">Role</th>
          </tr>
        </thead>
        <tbody>
          {data?.map?.(user => {
            return <tr key={user.id}
              onClick={() => {
                if (userr.role === 'admin') presentDialog(props => {
                  return (
                    <EditUserDialog
                      isOpen={props.isOpen}
                      onClose={props.onClosed}
                      onClosed={props.onClosed}
                      onSubmit={async (values:any) => {
                        await callMethod('users.update', { id: user.id, ...values })
                        queryClient.invalidateQueries('users');
                        props.close();
                      }}
                      onClickChangePassword={async (confirmed, done) => {
                        const newPassword = window.prompt('Enter password');
                        if (newPassword) {
                          confirmed();
                          await callMethod('users.changePassword', { id: user.id, password: newPassword })
                          done();
                        }
                      }}
                      onClickDelete={async (confirmed) => {
                        if (window.confirm('Are you sure?')) {
                          confirmed();
                          await callMethod('users.delete', { id: user.id });
                          queryClient.invalidateQueries('users');
                          props.close();
                        }
                      }}
                      user={user}
                    />
                  )
                })
              }}
            >
              <td>{user.username}</td>
              <td>{user.name}</td>
              <td>{_.upperFirst(user.role)}</td>
            </tr>
          })}
          </tbody>
        </HTMLTable>}

      {userr.role == 'admin' && <Button className="add-button" icon="add" onClick={() => {
        presentDialog(props => {
          return (
            <CreateUserDialog
              isOpen={props.isOpen}
              onClosed={props.onClosed}
              onClose={() => {
                props.close();
              }}
              onSubmit={async (values:any) => {
                await callMethod('users.create', values);
                queryClient.invalidateQueries('users');
                props.close();
              }}
            />
          )
        });
      }} />}

    </div>
  );
}
