import React, { useContext } from 'react';
import { Alignment, Button, Navbar } from '@blueprintjs/core';
import { useNavigate } from 'react-router';
import { UserContext } from './UserContext';

interface HeaderProps {
  active: string;
  right?: any;
  onNav?: any;
}
export function Header(props: HeaderProps) {
  const navigate = useNavigate();

  const {user} = useContext(UserContext);
  return (
    <Navbar className="header">
      <Navbar.Group align={Alignment.LEFT}>
        <Navbar.Heading>W2W Credit Cards</Navbar.Heading>
        <Navbar.Divider />
        <Button className="bp5-minimal" icon="home" text="Cards" active={props.active === 'cards'} onClick={() => navigate('/')} />
        {user.role === 'admin' && <Button className="bp5-minimal" icon="user" text="Users" active={props.active === 'users'} onClick={() => navigate('/users')} />}
      </Navbar.Group>
      <Navbar.Group className="right" align={Alignment.RIGHT}>
        {props.right}
        <Button text="Logout"
          onClick={() => {
            localStorage.removeItem('authToken');
            window.location.href = '/'
            // window.location.reload();
          }}
        />
      </Navbar.Group>


    </Navbar>
  );
}
